<template>
  <div class="flex w-full flex-col" v-if="researchArticles">
    <div class="space-y-3">
      <div>
        <div v-for="item in researchArticles" :key="item.id">
          <div class="flex flex-row space-x-3 rounded-sm px-2 py-1.5">
            <a :href="gotoArticle(item)" class="mr-1" target="_blank">
              <img class="h-20 w-32 rounded object-cover" :src="item.company_cover_image" alt="" />
            </a>
            <div class="m-auto w-full pr-2">
              <div class="mb-2 line-clamp-1 text-sm font-semibold">
                <a :href="gotoArticle(item)" class="w-full" target="_blank">
                  {{ item.title }}
                </a>
              </div>
              <div class="flex flex-row justify-between text-xs text-gray-400">
                <span class="line-clamp-1 w-1/2">
                  <a :href="`/company/${item.company_uid}`" target="_blank">
                    {{ item.company_name }}
                  </a>
                </span>
                <span class="line-clamp-1">{{ relativeTime(item.created_at) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { relativeTime } from '@/composeables/filters';
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';

const $http = useHttp();
const $emitter = useEmitter();

const props = defineProps({
  researchArticles: {
    type: Array,
    default: () => []
  },
  coin: {
    type: Object,
    default: () => {}
  }
});

onMounted(() => {
  $emitter.$on('favorite-publishers-updated', () => {
    loadData();
  });
  loading.value = false;
});

onBeforeUnmount(() => {
  $emitter.$off('favorite-publishers-updated');
});

const loading = ref(true);
const view = ref(window.localStorage.getItem('viewtype') || 'grid');
const articles = ref([]);

async function loadData() {
  try {
    let response = await $http.get('/research', { params: { coin: props.coin?.name } });
    articles.value = response.data.articles;
  } finally {
    loading.value = false;
  }
}

watch(view, () => {
  window.localStorage.setItem('viewtype', view.value);
});

function gotoArticle(article) {
  return `/research/article/${article.slug}`;
}
</script>
